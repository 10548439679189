import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        rootUrl: 'https://reallygood-kaitori.com',
        categoryList: [],
        resultList: [],
        prefList: [],
        buyingTypeList: [],
        shopList: [],
    },
    getters: {
        rootUrl(state) {
            return state.rootUrl
        },
        login(state) {
            return state.login
        },
        categoryList(state) {
            return state.categoryList;
        },
        selectedCategoryList: (state) => (categoryIdList) => {
            let selectedCategoryList = [];
            const categoryList = state.categoryList;
            categoryList.forEach(categoryData => {
                categoryIdList.forEach(categoryId => {
                    if (categoryData.id === categoryId) {
                        selectedCategoryList.push(categoryData);
                    } else {
                        // continue;
                    }
                });
            });
            return selectedCategoryList;
        },
        categoryData: (state) => (categoryId) => {
            return state.categoryList.find(categoryData => categoryData.id === categoryId);
        },
        resultList(state) {
            return state.resultList;
        },
        topResultList(state) {
            return state.resultList.filter(resultData => resultData.visible === true);
        },
        resultData: (state) => (resultId) => {
            return state.resultList.find(resultData => resultData.id === resultId);
        },
        productList: (state, getters) => (categoryId) => {
            let categoryData = getters.categoryData(categoryId);
            return categoryData.productList;
        },
        topProductList: (state, getters) => (categoryId) => {
            let productList = getters.productList(categoryId);
            return productList.filter(resultData => resultData.visible === true);
        },
        productData: (state, getters) => (categoryId, productId) => {
            let productList = getters.productList(categoryId);
            return productList.find(productData => productData.id === productId);
        },
        groupData: (state, getters) => (categoryId, groupId) => {
            let categoryData = getters.categoryData(categoryId);
            return categoryData.groupList.find(groupData => groupData.id === groupId);
        },
        memberData: (state, getters) => (categoryId, groupId, memberId) => {
            let groupData = getters.groupData(categoryId, groupId);
            return groupData.memberList.find(memberData => memberData.id === memberId);
        },
        prefList(state) {
            return state.prefList;
        },
        prefData: (state) => (prefCode) => { // 「prefCode」という名前は国土交通省のに合わせてます
            return state.prefList.find(prefData => prefData.prefCode === prefCode);
        },
        buyingTypeList(state) {
            return state.buyingTypeList;
        },
        buyingTypeData: (state) => (buyingTypeId) => {
            return state.buyingTypeList.find(buyingTypeData => buyingTypeData.id === buyingTypeId);
        },
        shopList(state) {
            return state.shopList;
        },
    },
    mutations: {
        setRootUrl(state, payload) {
            state.rootUrl = payload;
        },
        setPrefList(state, payload) {
            state.prefList = payload;
        },
        setBuyingTypeList(state, payload) {
            state.buyingTypeList = payload;
        },
        setCategoryList(state, payload) {
            state.categoryList = payload;
        },
        setResultList(state, payload) {
            state.resultList = payload;
        },
        setLogin(state, payload) {
            state.login = payload;
        },
        setShopList(state, payload) {
            state.shopList = payload.shopList;
        },
    },
    actions: {
        setRootUrl({
            commit
        }) {
            let rootUrl = process.env.NODE_ENV === 'production' ? "https://reallygood-kaitori.com" : "http://localhost:8001";
            commit('setRootUrl', rootUrl);
        },
        setGeneralData({
            getters,
            commit
        }) {
            axios
                .get(`${getters.rootUrl}/api/get-general-data`)
                .then((response) => {
                    console.log(response);
                    if (response.data.status === 200) {
                        commit('setPrefList', response.data.prefList);
                        commit('setBuyingTypeList', response.data.buyingTypeList);
                        return;
                    } else {
                        alert("エラーが発生しました。");
                        return;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setCategoryList({
            getters,
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${getters.rootUrl}/api/category/get-category-list`)
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === 200) {
                            commit('setCategoryList', response.data.categoryList);
                        } else {
                            alert("エラーが発生しました。");
                        }
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            })
        },
        setResultList({
            getters,
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${getters.rootUrl}/api/result/get-result-list`)
                    .then((response) => {
                        console.log(response);
                        if (response.data.status === 200) {
                            commit('setResultList', response.data.resultList);
                        } else {
                            alert("エラーが発生しました。");
                        }
                        resolve();
                    })
                    .catch((err) => {
                        console.log(err);
                        reject();
                    });
            })
        },
        setLogin({
            commit
        }, payload) {
            commit('setLogin', payload);
        },
        loginCheck({
            getters
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${getters.rootUrl}/api/auth/auth-check`)
                    .then(res => {
                        console.log(res.data);
                        //[status: 200, message: "ログイン失敗"]
                        resolve(res);
                        // resolve(res.data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            })
        },
        setShopList({
            commit
        }) {
            // 
            const shopList = [
                {
                    id: '1',
                    name: '名古屋店',
                    path: '/nagoya',
                    phoneNumber: "052-211-7931",
                    buyingPhoneNumber: "0120-590-510",
                    address: "愛知県名古屋市中区橘1-27-6<br />石橋ビル2F",
                    googleMapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3262.1372050752743!2d136.90260341558633!3d35.153198780320864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6003776ed2e36ef9%3A0x68237c03ae9b7e9a!2z44Oq44Ki44Oq44O844Kw44OD44OJ!5e0!3m2!1sja!2sjp!4v1652162457277!5m2!1sja!2sjp',
                    businessHoursText: "12:00-20:00 年中無休",
                },
                {
                    id: '2',
                    name: '岡崎店',
                    path: '/okazaki',
                    phoneNumber: "0564-83-6949", // https://www.chatwork.com/#!rid321735475-1900444394117398528
                    // phoneNumber: "050-6883-9131",
                    buyingPhoneNumber: "0120-590-510",
                    address: "愛知県岡崎市明大寺町西郷中32-35<br />メビウスビル1F",
                    googleMapSrc: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3270.2116724448856!2d137.1593118878544!3d34.9513029996864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6004bd7be4fa692f%3A0xc946b122e23ba841!2z44Oq44Ki44Oq44O844Kw44OD44OJ5bKh5bSO5bqX!5e0!3m2!1sja!2sjp!4v1729579967436!5m2!1sja!2sjp',
                    businessHoursText: " 12:00-19:00<br />定休日：水曜日",
                },
            ];

            // 
            const payload = {
                shopList: shopList
            }
            commit('setShopList', payload);
        },
    },
    modules: {},
    plugins: [createPersistedState()]
})
